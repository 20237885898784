import React, { useEffect, useState } from 'react';
import './App.css';
import './js-cloudimage-360-view';

const My360ViewComponent = () => {
  const [folderUrl, setFolderUrl] = useState('https://exs-360-images.s3.eu-west-2.amazonaws.com/ERIS/');
      // Handler to update state based on selection
    console.log('current url1:', folderUrl);

    const handleSelect = (url) => {
      setFolderUrl(url);
      console.log('current url2:', url );
    };
  
    useEffect(() => {
      // Ensure that CI360 is loaded
      if (window.CI360) {
        console.log('Initializing CI360 viewers');
        window.CI360.init();
      }
    
      let timeoutId;
    
      // Function to call when updating
      const updateViewer = () => {
        timeoutId = setTimeout(() => {
          if (window.CI360 && window.CI360._viewers) {
            console.log('Viewers currently available:', window.CI360._viewers);
            window.CI360.update('my-360-view', true);
          }
        }, 500);
      };
    
      // Call updateViewer when folderUrl changes
      updateViewer();
    
      return () => {
        // Perform any necessary cleanup
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [folderUrl]); // Dependency array to ensure effect runs on folderUrl change
    
  return (
    <div className="container mx-auto bg-gray-800 text-white items-center justify-center p-8 pb-12 xl:px-64 xl:py-16">
      <div className="gap-8 lg:columns-2 sm:columns-1 md:columns-1">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl text-[#000000] p-8">
    <div className="justify-center items-center px-5 w-full h-full">
        <div className="justify-center">
            <h1 className="font-bold text-2xl">Welcome to the Best 360 Product Viewer </h1>
            <div className="text-lg p-8">Select product from the list to see the magic happen!</div>
            <div className="dropdown grid relative justify-center">
    <button className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
      <span className="mr-1">Examples</span>
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </button>
    <ul className="dropdown-menu text-gray-700 pt-1">
        <li className="">
          <a 
            className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" 
            href="#"
            onClick={() => handleSelect('https://exs-360-images.s3.eu-west-2.amazonaws.com/Armchair_teagan/')}
          >
            One
          </a>
        </li>
        <li className="">
          <a 
            className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" 
            href="#"
            onClick={() => handleSelect('https://exs-360-images.s3.eu-west-2.amazonaws.com/ERIS/')}
          >
            Two
          </a>
        </li>
      </ul>
  </div>
        </div>
    </div>
      </div>
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl sm:my-8 md:my-8 my-8">
      <div
      id="my-360-view"
      className="cloudimage-360"
      data-folder={folderUrl}
     // data-folder="https://www.elevationxstudios.com/wp-content/uploads/360/armchair_01/"
     // data-folder="https://cltbmlefma.cloudimg.io/exs-360-images.s3.eu-west-2.amazonaws.com/ERIS/""
      data-filename-x="{index}.jpg"
      data-amount-x="36"
      data-autoplay="true"
      data-play-once="true"
      
    //  data-fullscreen
      data-magnifier="10"
    //  data-box-shadow
     data-pointer-zoom="4"
    // data-zoom-factor="2"
  //   data-responsive="cltbmlefma"
  //    data-lazyload
    //  data-lazyload-selector="lozad"
    ></div>
    </div>
      </div>

    </div>
  );

  
};
export default My360ViewComponent;
