export const FALSY_VALUES = [
  false,
  0,
  null,
  undefined,
  'false',
  '0',
  'null',
  'undefined',
];
